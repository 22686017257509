import React from "react";
import { ThreeDots } from "react-loader-spinner";
import Message from "./Message";

const TypingIndicator = () => (
  <div style={{ display: "flex", justifyContent: "start" }}>
    <Message
      message={{
        position: "left",
        text: (
          <ThreeDots
            visible={true}
            height="20"
            width="20"
            color="#808080"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ),
        date: new Date(),
        loader: true,
      }}
    />
  </div>
);

export default TypingIndicator;
