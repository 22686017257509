import axios from "axios";
import { getCurrentUrl, getMetaId, getVideoNames } from "../helpers/helpers";
const api_path = "https://drtalks-llm.deepxhub.com";

const getLLMData = async (prompt, messages) => {
  try {
    // const response = await axios.post(`${api_path}/answer`, { prompt });
    const [meta_name, meta_id, meta_slug] = getMetaId();

    const response = await axios.post(`${api_path}/answer`, {
      prompt,
      url: getCurrentUrl(),
      // url: "https://drtalksdev.wpenginepowered.com/breast-cancer-summit/",
      history: messages,
      meta_id: meta_id !== "" ? +meta_id : 0,
      meta_name,
      meta_slug,
    });
    if (response.status_code === 200) {
      const answer = response.data.answer;
      const message = { role: "assistant", content: answer };
      return message;
    } else {
      console.error("Error from server: ", response);
    }
    return response.data.answer;
  } catch (error) {
    console.error("Error making request: ", error);
  }
};

const getSuggestionsByUrl = async (messages) => {
  const getSuggestions = async (jsonString) => {
    const suggestions = await jsonString
      .split("\n")
      .filter((str) => str.trim().length > 0);
    return suggestions.slice(1).map((str) => str.trim().replace(/^\d+\. /, ""));
  };

  const videoTitles = getVideoNames();

  const [meta_name, meta_id, meta_slug] = getMetaId();

  try {
    const response = await axios.post(`${api_path}/generate_suggestions`, {
      url: getCurrentUrl(),
      // url: "https://drtalksdev.wpenginepowered.com/breast-cancer-summit/",
      history: messages,
      meta_id: meta_id !== "" ? +meta_id : 0,
      meta_name,
      meta_slug,
      video_titles: videoTitles,
    });
    if (response.status === 200) {
      const suggestions = response.data.suggestions;
      const suggestionsArray = await getSuggestions(suggestions);
      return suggestionsArray;
    } else {
      console.error("Error from server: " + response.statusText);
    }
    return response.data.answer;
  } catch (error) {
    console.error("Error making request: ", error);
  }
};

export { getLLMData, getSuggestionsByUrl };
