import React from "react";
import {
  formatDate,
  getMessageBoxClass,
  getStyle,
  renderMessageWithTimestamp,
} from "../helpers/helpers";

const Message = ({ message }) => {
  const messageBoxClass = getMessageBoxClass(message.position);
  const content =
    message.position === "right"
      ? message.text
      : renderMessageWithTimestamp(message.text);

  return (
    <div style={getStyle(message.position)}>
      <div className={`${message?.loader ? "chatLoader" : messageBoxClass}`}>
        {content}
      </div>
      <span className="messageTime">{formatDate(message.date)}</span>
    </div>
  );
};

export default Message;
