import React, { memo } from "react";
import StartingsList from "./StartingsList";
import NeedHelp from "./NeedHelp";
import TypingIndicator from "./TypingIndicator";
import Suggestion from "./Suggestion";
import { ReactComponent as SuggestionIcon } from "../assets/suggestion.svg";
import Message from "./Message";
import { ReactComponent as SuggLabelIcon } from "../assets/sug-icon.svg";

const MessageList = memo(
  ({
    step,
    messages,
    isBotTyping,
    showSuggestions,
    handleSendMessage,
    messagesEndRef,
  }) => {
    return (
      <div className="messagesContainer">
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 16,
              paddingBottom: 16,
            }}
          >
            <span
              className="header"
              style={{
                fontSize: "18px",
              }}
            >
              Start by our top topics
            </span>
            <div className="startingButtonsContainer">
              <StartingsList />
            </div>
          </div>
          {messages.map((elem, index) => (
            <Message
              message={elem}
              index={index}
              key={`${index}_${elem.date}`}
            />
          ))}
          {isBotTyping && <TypingIndicator />}
        </div>
        <div>
          {!isBotTyping && showSuggestions.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
              }}
            >
              {step === 0 && (
                <span
                  className="header"
                  style={{
                    fontSize: "18px",
                  }}
                >
                  <SuggLabelIcon />
                  Suggested Questions
                </span>
              )}
              {showSuggestions.map((suggestion, index) => (
                <div key={index} className="suggestion-item">
                  <Suggestion
                    text={suggestion}
                    variant={"suggestions"}
                    onClick={() => handleSendMessage(suggestion)}
                    icon={<SuggestionIcon />}
                  />
                </div>
              ))}
            </div>
          )}
          <div ref={messagesEndRef} />
          {step === 0 && (
            <div
              style={{
                paddingTop: 24,
              }}
            >
              <NeedHelp />
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default MessageList;
