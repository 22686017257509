import React from "react";
import "../App.css";

const Suggestion = ({ text, index, variant, onClick, icon }) => {
  const handleOnClick = () => {
    onClick?.();
  };

  return (
    <div
      key={`${index}-${variant}`}
      className={
        variant === "starter" ? "messageStartings" : "messageSuggestion"
      }
      onClick={handleOnClick}
    >
      <span
        style={{
          width: variant === "starter" ? "100%" : 380,
        }}
      >
        {text}
      </span>
      {icon}
    </div>
  );
};

export default Suggestion;
