import React, { useState } from "react";

const SendContainer = ({
  handleSendMessage,
  isBotTyping,

  inputMessage,
  setInputMessage,
}) => {
  const [inputIsValid, setInputIsValid] = useState(true);

  const handleChange = (value) => {
    if (!inputIsValid) setInputIsValid(true);
    setInputMessage(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage(inputMessage);
      setInputMessage("");
    }
  };

  const sendMessage = () => {
    setInputMessage("");
    handleSendMessage(inputMessage);
  };

  return (
    <div className="inputContainer">
      <input
        type="text"
        className="styledInput"
        placeholder="Type a reply"
        onChange={(e) => handleChange(e.target.value)}
        value={inputMessage}
        disabled={isBotTyping}
        style={{
          border: inputIsValid ? "inputInvalid" : "red",
        }}
        onKeyDown={handleKeyDown}
      />

      <button
        className="sendButton"
        onClick={() => sendMessage(inputMessage)}
        disabled={isBotTyping || !inputIsValid || inputMessage.trim() === ""}
      >
        Send
      </button>
    </div>
  );
};

export default SendContainer;
