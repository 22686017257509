import React from "react";
import { ReactComponent as NeedHelpIcon } from "../assets/need-help.svg";

const NeedHelp = () => {
  const handleRedirect = () => {
    window.open("https://drtalks.zendesk.com/hc/en-us", "_blank");
  };

  return (
    <div className="needHelp" onClick={() => handleRedirect()}>
      <NeedHelpIcon width={56} height={56} />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span
          style={{
            fontSize: "24px",
            fontWeight: 700,
            lineHeight: "35px",
          }}
          className="needHelpLabel"
        >
          Need help?
        </span>
        <span
          style={{
            fontSize: "14px",
          }}
          className="heeboText needHelpSmall"
        >
          Find guides and find quick solutions to common questions.
        </span>
      </div>
    </div>
  );
};

export default NeedHelp;
