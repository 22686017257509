import "./App.css";
import ChatApp from "./components/ChatApp";
import { RecoilRoot } from "recoil";

function App() {
  return (
    <RecoilRoot>
      <ChatApp />
    </RecoilRoot>
  );
}

export default App;
