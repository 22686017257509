import { atom } from "recoil";

export const messagesState = atom({
  key: "messagesState",
  default: [],
});

export const initializeMessagesFromLocalStorage = () => {
  const storedMessages = JSON.parse(sessionStorage.getItem("chatHistory"));
  if (storedMessages) {
    return storedMessages;
  } else {
    return [];
  }
};
