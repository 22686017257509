import React, { useEffect, useRef, useState, useCallback } from "react";
import "react-chat-elements/dist/main.css";
import { ReactComponent as ChatIcon } from "../assets/logo.svg";

import { getLLMData, getSuggestionsByUrl } from "../api/api";
import "../App.css";
import ChatHeader from "./ChatHeader";
import { sendMessage } from "../api/messagesHelper";
import SendContainer from "./SendContainer";
import { useRecoilState } from "recoil";

import {
  initializeMessagesFromLocalStorage,
  messagesState,
} from "../store/messageHistoryAtom";
import { clearLocalStorage } from "../api/localStorageHelper";
import MessageList from "./MessageList";
import { playbackVideo } from "../helpers/helpers";
const ChatApp = () => {
  const [isBotTyping, setIsBotTyping] = useState(false); //
  const [inputMessage, setInputMessage] = useState(""); //
  const [messages, setMessages] = useRecoilState(messagesState);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const [step, setStep] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [currentUrl, setCurrentUrl] = useState(window.location.href);

  const messagesEndRef = useRef(null);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isBotTyping, isChatOpen, showSuggestions]);

  const getSugg = async () => {
    const res = await getSuggestionsByUrl(messages);
    setSuggestions(res);
  };

  const [chatAnimation, setChatAnimation] = useState("");

  useEffect(() => {
    if (isChatOpen) {
      setChatAnimation("open");
      if (
        currentUrl !== "https://drtalksdev.wpenginepowered.com/" ||
        currentUrl !== "https://drtalks.com/"
      ) {
        getSugg();
      }
    }
  }, [isChatOpen]);

  const toggleChat = () => {
    //
    if (isChatOpen) {
      setChatAnimation("close");
      setTimeout(() => setIsChatOpen(false), 500);
    } else {
      setIsChatOpen(true);
      setChatAnimation("open");
    }
  };

  const closeChat = async () => {
    //
    setChatAnimation("close");

    await new Promise((resolve) => setTimeout(resolve, 200));

    setIsChatOpen(false);
    clearLocalStorage();
    setInputMessage("");
    setShowSuggestions([]);
    setMessages([]);
    setStep(0);
  };

  const handleSendMessage = useCallback(
    //
    async (messageText) => {
      setIsBotTyping(true);

      setShowSuggestions([]);
      sendMessage(setMessages, messageText, undefined, undefined, messages);
      const answer = await getLLMData(messageText, messages);
      handleBotResponse(answer);
    },
    [messages, setMessages]
  );

  const handleBotResponse = async (messageText) => {
    setSuggestions([]);
    if (!messageText) {
      sendMessage(setMessages, "Error connection", "left", undefined, messages);
      setIsBotTyping(false);
      return;
    }
    try {
      sendMessage(setMessages, messageText, "left", undefined, messages);
    } catch (error) {
      setIsBotTyping(false);
      console.error("Error making request: ", error);
      sendMessage(setMessages, "Error connection", "left", undefined, messages);
      return;
    }
    getSugg();
    setIsBotTyping(false);
  };

  useEffect(() => {
    if (!isBotTyping && suggestions?.length > 0) {
      setShowSuggestions([...suggestions]);
    }
  }, [isBotTyping, suggestions]);

  useEffect(() => {
    if (messages.length > 0) setStep(1);
    return () => {};
  }, [messages]);

  useEffect(() => {
    const initialMessages = initializeMessagesFromLocalStorage();
    setMessages(initialMessages);
    messagesState > 0 && setIsChatOpen(true);
  }, []);

  useEffect(() => {
    if (currentUrl.includes("t=")) {
      playbackVideo();
    }
  }, [currentUrl]);

  return (
    <div style={{ position: "relative", maxWidth: "480px" }}>
      {!isChatOpen && (
        <ChatIcon
          width={70}
          height={70}
          onClick={toggleChat}
          className="chatButton"
        />
      )}
      {isChatOpen && (
        <div className={`chatContainer ${chatAnimation}`}>
          <ChatHeader toggleChat={toggleChat} closeChat={closeChat} />
          <MessageList
            step={step}
            currentUrl={currentUrl}
            messages={messages}
            isBotTyping={isBotTyping}
            showSuggestions={showSuggestions}
            handleSendMessage={handleSendMessage}
            messagesEndRef={messagesEndRef}
          />
          <SendContainer
            handleSendMessage={handleSendMessage}
            isBotTyping={isBotTyping}
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
          />
        </div>
      )}
    </div>
  );
};

export default ChatApp;
