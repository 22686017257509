import React from "react";
import Suggestion from "./Suggestion";

const defaultStartings = [
  "Hypertension",
  "Cancer",
  "Alzheimers",
  "Diabetes",
  "Heart Disease",
];

const StartingsList = () => {
  const handleClickStarting = (topic) => {
    const formattedTopic = topic.toLowerCase().split(" ").join("-");
    window.open(`https://drtalks.com/topics/${formattedTopic}/`, "_blank");
    // window.location.href = `https://drtalks.com/topics/${formattedTopic}/`;
  };

  return defaultStartings.map((text, index) => (
    <Suggestion
      text={text}
      index={index}
      variant={"starter"}
      onClick={() => handleClickStarting(text)}
      key={`${index * 1245}-${new Date()}`}
    />
  ));
};

export default StartingsList;
