import React from "react";
import { ReactComponent as Close } from "../assets/close.svg";
import { ReactComponent as Hide } from "../assets/minimize.svg";
import { ReactComponent as ChatIcon } from "../assets/logo.svg";
import "../App.css";
import { getMetaId } from "../helpers/helpers";

const ChatHeader = ({ toggleChat, closeChat }) => {
  const metaData = getMetaId();

  return (
    <div className="chatHeaderContainer">
      <div className="chatHeader">
        <ChatIcon width={50} height={50} />
      </div>
      <div className="chatTitle">
        {metaData?.[0] ? (
          <div className="summitTitleName">
            Hi, I'm {metaData[0]} AI, ask me anything!
          </div>
        ) : (
          <>
            DrTalks <span className="chatTitleSpan">AI Bot</span>
          </>
        )}
      </div>
      <div className="headerButtons">
        <button onClick={toggleChat} className="btnCustom">
          <Hide width={32} height={32} />
        </button>
        <button onClick={() => closeChat()} className="btnCustom">
          <Close width={32} height={32} />
        </button>
      </div>
    </div>
  );
};

export default ChatHeader;
