import { v4 as uuidv4 } from "uuid";
import { updateLocalStorageChatHistory } from "./localStorageHelper";

const sendMessage = (
  setMessages,
  text,
  position = "right",
  type = "text",
  messages = []
) => {
  const newMessage = {
    id: uuidv4(),
    position: position,
    type: type,
    text: text,
    date: new Date(),
  };
  updateLocalStorageChatHistory([...messages, newMessage]);
  setMessages((prevMessages) => [...prevMessages, newMessage]);
};

export { sendMessage };
