import _ from "lodash";

const updateLocalStorageChatHistory = (messages) => {
  const chatHistory = JSON.parse(sessionStorage.getItem("chatHistory")) || [];
  const newMessage = messages[messages.length - 1];
  const uniqueMessages = _.uniqBy([...chatHistory, newMessage], "id");
  sessionStorage.setItem("chatHistory", JSON.stringify(uniqueMessages));
};

const getMessagesFromLocalStorage = () => {
  const storedMessages = sessionStorage.getItem("chatHistory");
  if (storedMessages) {
    return JSON.parse(storedMessages);
  } else {
    return [];
  }
};

const clearLocalStorage = () => {
  sessionStorage.removeItem("chatHistory");
};

export {
  updateLocalStorageChatHistory,
  getMessagesFromLocalStorage,
  clearLocalStorage,
};
